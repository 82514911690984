/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../header/header"
import { ToastContainer } from "react-toastify"
import styles from "./login-layout.module.scss"
import ModalWrapper from "../../common/modal/modal-wrapper/modal-wrapper"

const LoginLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleLoginQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  console.log("rendering LoginLayout")

  return (
    <>
      {/* Every layout needs this to be able to display reusable modal windows (e.g. alertModal) */}
      <ModalWrapper />
      <div className={styles.header}>
        <Header siteTitle={data.site.siteMetadata.title} />
      </div>
      <main> {children} </main>
      <ToastContainer />
    </>
  )
}

LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LoginLayout
