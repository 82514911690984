import React, { useState } from "react"
import { navigate } from "gatsby"
import { handleLogin, strapiLogin, isLoggedIn, isAFPABranch} from "../../services/auth"
import TextField from "../common/form/text-field/text-field"
import { useForm } from "react-form"
import styles from "./login.module.scss"
import ConfirmNavLink from "../common/confirm-nav-link/confirm-nav-link"
import ModalWindow from "../common/modal-window/modal-window"
import ForgotPassword from "../common/forgot-password/forgot-password"
import auth from "../../services/auth"
import { MdError } from "react-icons/md"
import PropTypes from "prop-types"
import classNames from "classnames"

const Login = ({ siteTitle }) => {
  const [showMessage, setShowMessage] = useState(false)
  const [error, setError] = useState()
  const [modalOpen, setModalOpen] = useState(false)

  const defaultValues = React.useMemo(() => {
    return { user: "", password: "" }
  }, [])
  const {
    Form,
    meta: { canSubmit },
  } = useForm({
    defaultValues: defaultValues,
    debugForm: false,
    onSubmit: values => {
      if (isAFPABranch()) {
        handleLogin(values).then(
          result => {
            navigate("/")
          },
          error => {
            setShowMessage(true)
            setError(error.message)
          }
        )
      }
      else {
        handleLogin(values).then(
          result => {
            navigate("/")
          },
          error => {
            setShowMessage(true)
            setError(error.message)
          }
        )
      }

    },
    validate: () => {
      setShowMessage(false)
    },
  })

  if (isLoggedIn()) {
    navigate(`/`)
  }

  const getLoginError = () => {
    if (showMessage) {
      return (
        <div className={styles.loginError}>
          <MdError /> {error}
        </div>
      )
    }
  }

  const onClick = () => {
    setModalOpen(true)
  }

  const getModalWindow = () => {
    const onModalClose = () => {
      setModalOpen(false)
    }
    if (modalOpen) {
      return (
        <ModalWindow title="Forgot Password" closeHandler={onModalClose}>
          <ForgotPassword />
        </ModalWindow>
      )
    } else {
      return ""
    }
  }

  return (
    <div className={classNames(styles.wrapper, "login-bg")}>
      <div
        id="headerLogo"
        className={classNames(styles.loginLogo, "login-logo")}
      >
        <ConfirmNavLink to="/">{siteTitle}</ConfirmNavLink>
      </div>
      <div className={styles.login}>
        <div className={styles.loginOverlay}>
          <Form className={styles.formSignin}>
            <h2 className={styles.loginTitle}>Sign In</h2>
            {auth.isAFPABranch() && 
              <div className={styles.loginDescription}>
                Don't have an account yet?
                <ConfirmNavLink to={"/app/signup"} className={styles.signUp}>
                  {' Sign Up'}
                </ConfirmNavLink>
              </div>
            }
            {getLoginError()}
            {getModalWindow()}
            <div className={styles.fields}>
              <TextField
                field="user"
                placeholder="Username"
                prependIcon="MdPerson"
                required
              />
              <TextField
                type="password"
                field="pass"
                placeholder="Password"
                prependIcon="MdLock"
                required
              />
            </div>
            <input
              className={styles.loginBtn}
              id="loginButton"
              type="submit"
              value="Sign In"
              disabled={!canSubmit}
            />
            {auth.isAFPABranch() && 
              <div className={styles.pointer}>
                <a id="forgot-password" onClick={onClick}>
                    Forgot Password?
                </a>
              </div>
            }  
          </Form>
        </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  siteTitle: PropTypes.string,
}

Login.defaultProps = {
  siteTitle: ``,
}

export default Login
